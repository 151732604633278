import { Card, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ScheduleMeeting } from 'react-schedule-meeting';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AppointmentForm from './AppointmentForm'
import axios from 'axios';
import { API_URL } from '../configs/endpoint';
import './styles.css'
import AppointmentSuccess from './AppointmentSuccess';

export default function AppointmentSchedule() {
    // this generates basic available timeslots for the next 6 days
    const [appointmentData, setAppointmentData] = useState({
        startTime: new Date(), name: ""
    })


    const [isLoading, setIsLoading] = useState({
        errorMessage: "",
        loading: true
    })

    const slotTime = 15
    const [availableTimeslots, setAvailableTimeslots] = useState([])
    const [currentScreen, setCurrentScreen] = useState("calendar")
  
    const onTimeSelect = (e) => {
        setAppointmentData({
            ...appointmentData,
            startTime: e.startTime
        })
        setCurrentScreen("form")
    }
    const [formResponse, setFormResponse] = useState({})
    useEffect(() => {
        axios.get(`${API_URL}/time-slots`)
            .then((response) => {
                var final = response.data.map((item, index)=> {
                    return (
                        {
                            id: index,
                            startTime: new Date(item[0]),
                            endTime: new Date(item[1]),
                        }
                    )
                })
                setAvailableTimeslots(final)
            })
            .catch((error) => {
                
            });
    }, [])


    return (
        <Card variant="outlined">
            {currentScreen === 'calendar' &&
                <Grid container>
                    <Grid item md={4}>
                        <div style={{
                            padding: "20px"
                        }}>
                            <h3>EKKO Appointment</h3>
                            <div style={{
                                display: 'flex'
                            }}>
                                <AccessTimeIcon />
                                <b style={{
                                    padding: "0px 10px"
                                }}>{slotTime} min</b>
                            </div>
                            <p style={{
                                paddingTop: "10px"
                            }}>
                                Have questions about your condition? No problem! We are happy to help and can walk you through any problems you may have.
                            </p>

                            <div style={{
                        marginTop: "40px"
                    }}>
                        <b>Session Fee</b>
                        <h1 style={{ marginTop: "15px" }}>
                            $20 <span style={{ color: "#09F" }}>or</span> 4,000PKR
                        </h1>
                    </div>
                        </div>
                    </Grid>
                    <Grid item md={8}>
                        <ScheduleMeeting
                            borderRadius={10}
                            style={{
                                boxShadow: 'none'
                            }}
                            primaryColor="#090"
                            eventDurationInMinutes={slotTime}
                            availableTimeslots={availableTimeslots}
                            onStartTimeSelect={e => onTimeSelect(e)}
                            startTimeListStyle={window.innerWidth<500?'grid':"scroll-list"}
                        //selectedStartTime={}
                        />
                    </Grid>
                </Grid>
            }
            {currentScreen === "form" &&
                <AppointmentForm
                    slotTime={slotTime}
                    setResponse={setFormResponse}
                    appointmentData={appointmentData}
                    setCurrentScreen={setCurrentScreen}
                />
            }
            {currentScreen === "details" &&
                <AppointmentSuccess
                    slotTime={slotTime}
                    formResponse={formResponse}
                    appointmentData={appointmentData}
                />
            }

        </Card>
    );
}