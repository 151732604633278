import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { API_URL } from '../configs/endpoint';

import './styles.css'
import { ArrowBack } from '@mui/icons-material';
export default function AppointmentForm(props) {
    // this generates basic available timeslots for the next 6 days
    const { appointmentData, slotTime } = props

    const [formSubmit, setFormSubmit] = useState({
        errorMessage: "",
        loading: false
    })

    const onFormSubmit = (e) => {
        e.preventDefault()
        setFormSubmit({
            isLoading: true, errorMessage: ""
        })
        const formData = new FormData(e.target)
        console.log(e)
        console.log(formData.get("name"))
        console.log(formData.get("attachments"))
        formData.append("startTime", appointmentData.startTime)
        axios.post(`${API_URL}/appointment`, formData)
            .then((response) => {
                setFormSubmit({
                    isLoading: false, errorMessage: ""
                })
                document.getElementById("appointment-form").reset();
                props.setCurrentScreen("details");
                props.setResponse(response.data);
            })
            .catch((error) => {
                setFormSubmit({
                    isLoading: false, errorMessage: "There is some error. Try again"
                })
            });

    }

    return (

        <Grid container>
            <Grid item md={6}>
                <div style={{
                    padding: "20px"
                }}>
                    <div style={{
                        display: 'flex',
                        margin: '20px 0px'
                    }}>
                        <div className='arrowButton' onClick={() => props.setCurrentScreen("calendar")}>
                            <ArrowBack style={{
                                marginTop: "2px"
                            }} />
                        </div>
                        <h3 style={{
                            margin: 0
                        }}>

                            Fill In Details</h3>
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <AccessTimeIcon />
                        <b style={{
                            padding: "0px 10px"
                        }}>{slotTime} min</b>
                    </div>
                    <p style={{
                        paddingTop: "10px"
                    }}>
                        Please upload any previous reports as attachments and also provide any other details that are relevant.
                        
                    </p>
                    <p style={{
                        paddingTop: "10px", fontWeight: 'bold', color: "#09F"
                    }}>
                        {appointmentData.startTime.toDateString()} at {appointmentData.startTime.toLocaleTimeString()}
                    </p>
                    <p style={{
                        paddingTop: "0px", fontWeight: 'bold', color: "#03F"
                    }}>
                        {
                            // Current timezone is 
                            Intl.DateTimeFormat().resolvedOptions().timeZone

                        }
                    </p>

                </div>
            </Grid>
            <Grid item md={6} sm={12}>
                <form id="appointment-form" className='appointment-form' onSubmit={onFormSubmit}>
                    <div className='input-group'>
                        <label className='input-label'>What is your full name?</label>
                        <input type={'text'} name='name' required className='form-control' placeholder='Full Name' />
                    </div>
                    <div className='input-group'>
                        <label className='input-label'>What is your email address?</label>
                        <input type={'email'} name='email' required className='form-control' placeholder='example@mail.com' />
                    </div>
                    <div className='input-group'>
                        <label className='input-label'>What is your phone? <span className='text-red'>*</span></label>
                        <input type={'tel'} name='phone' required className='form-control' placeholder='923034433221' />
                    </div>
                    <div className='input-group'>
                        <label className='input-label'>Attachments</label>
                        <input type={'file'} multiple name='attachments' className='form-control' placeholder='Full Name' />
                    </div>
                    <div className='input-group'>
                        <label className='input-label'>Details? <span className='text-red'>*</span></label>
                        <textarea name='details' className='form-control' placeholder='Details' rows={5} />
                    </div>
                    <div className='input-group'>
                        {formSubmit.errorMessage !== "" &&
                            <p className='text-danger'>{formSubmit.errorMessage}</p>
                        }
                        <Button variant='contained' type={'submit'} color='primary'>{
                            formSubmit.isLoading ? 'Submitting...' : 'Submit'
                        }</Button>
                    </div>
                </form>
            </Grid>
        </Grid>
    );
}