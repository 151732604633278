import { Grid } from '@mui/material';
import React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

import './styles.css'
import { CheckCircle } from '@mui/icons-material';
export default function AppointmentSuccess(props) {
    // this generates basic available timeslots for the next 6 days
    const { appointmentData, slotTime } = props



    return (
        <Grid container>
            <Grid item md={6}>
                <div style={{
                    padding: "20px"
                }}>



                    <div style={{
                        display: 'flex',
                        margin: '20px 0px'
                    }}>

                        <CheckCircle style={{
                            color: 'green'
                        }} />
                        <h3 style={{
                            margin: 0
                        }}>

                            Appointment Created</h3>
                    </div>



                    <div style={{
                        display: 'flex'
                    }}>
                        <AccessTimeIcon />
                        <b style={{
                            padding: "0px 10px"
                        }}>{slotTime} min</b>
                        
                    </div>
                    <p style={{
                            marginTop: 10, fontWeight: 'bold', color: "#09F"
                        }}>
                            {appointmentData.startTime.toDateString()} at {appointmentData.startTime.toLocaleTimeString()}
                        </p>

                    <div style={{
                        marginTop: "40px"
                    }}>
                        <b>Session Fee</b>
                        <h1 style={{ marginTop: "15px" }}>
                            $20 <span style={{ color: "#09F" }}>or</span> 4,000PKR
                        </h1>
                    </div>



                </div>
            </Grid>
            <Grid item md={6}>
                <div style={{
                    padding: "10px"
                }}>        
                    <h3 className='text-center'>Please note that in order to confirm your appointment, you need to upload the receipt of the payment to the account mentioned below.</h3>
                        
                    <table className='bank-details-table' style={{
                        marginTop: "30px", marginBottom: "30px"
                    }}>
                        <tbody>
                            <tr>
                                <th>Id</th>
                                <td>{props.formResponse.appointment_id}</td>
                            </tr>
                            <tr>
                                <th>Account Title</th>
                                <td>RISETECH PVT LT</td>
                            </tr>
                            <tr>
                                <th>Bank Name</th>
                                <td>Habib Bank Limited</td>
                            </tr>
                            <tr>
                                <th>IBAN</th>
                                <td>PK78HABB0022927917369403</td>
                            </tr>
                        </tbody>
                    </table>

                    <p>An appointment will only be confirmed via email once your receipt of payment is received at
                        <b className='text-center text-primary'> contact@risetech.ai</b></p>
                    <p className='text-center'>
                        Thank you for choosing EKKO.</p></div>
            </Grid>
        </Grid>
    );
}