
import { Container } from '@mui/system';
import './App.css';
import AppointmentSchedule from './Components/AppointmentSchedule';
import EKKOLogo from './assets/images/logo.png'
function App() {
  return (
    <Container>
      <div className='ekkoLogo'>
        <img src={EKKOLogo} height={40} width={40} alt="EKKO" />
        <h2 style={{
          margin: "0px 20px",
        textAlign: 'center',
      }}>EKKO Appointment</h2>
    
      </div>
      <p style={{
        textAlign: 'center', display: 'block'
      }}>(For Online Appointment only)</p>
     <p style={{
        textAlign: 'center', display: 'block', color: 'blue'
      }}>If you are near Islamabad/Rawalpind, you can visit Dr. in person.</p>
      <p style={{
        textAlign: 'center', display: 'block', color: 'coral'
      }}>
        Mr Shahbaz Khalid Ranjha, Family Health Hospital. Contact: +92 314 5135507</p>
      
      <AppointmentSchedule />
    </Container>
  );
}

export default App;
